import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';

import withComponentName from '../../../decorators/withComponentName';

import benefitsLogo from '../../../assets/finprompt/finprompt-logo-benefits.png';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const AuthFinPromptBenefits = () => (
  <div className={cx('finprompt-benefits')}>
    <div className={cx('finprompt-benefits__content')}>
      <div className={cx('finprompt-benefits__logo-wrapper')}>
        <img
          className={cx('finprompt-benefits__logo')}
          src={benefitsLogo}
          alt="finprompt logo"
        />
      </div>
      <h1 className={cx('finprompt-benefits__title')}>
        FinPrompt.ai
      </h1>
      <ul className={cx('finprompt-benefits__list', 'benefits-list')}>
        <li className={cx('benefits-list__item')}>
          <FontAwesomeIcon className={cx('benefits-list__item-icon')} icon={faCheck} />
          <span className={cx('benefits-list__item-text')}>
            Make your own requests and continue your past conversation.
          </span>
        </li>
        <li className={cx('benefits-list__item')}>
          <FontAwesomeIcon className={cx('benefits-list__item-icon')} icon={faCheck} />
          <span className={cx('benefits-list__item-text')}>
            Get responses with real-time data from the news,
            official documents, reports, and other publicly available content.
          </span>
        </li>
        <li className={cx('benefits-list__item')}>
          <FontAwesomeIcon className={cx('benefits-list__item-icon')} icon={faCheck} />
          <span className={cx('benefits-list__item-text')}>
            Interact with charts and see more visual components.
          </span>
        </li>
        <li className={cx('benefits-list__item')}>
          <FontAwesomeIcon className={cx('benefits-list__item-icon')} icon={faCheck} />
          <span className={cx('benefits-list__item-text')}>
            Seamlessly connect to CityFALCON, the main platform, for
            deeper due diligence using more features, data, and content.
          </span>
        </li>
      </ul>
    </div>
  </div>
);

export default withComponentName(AuthFinPromptBenefits);
