import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import { setAuthModalOpened, setFinPromptAuthModalOpened } from '../../../actions/authentication.actions';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

import AuthenticationMainWrapper from '../../AuthenticationComponents/AuthenticationMainWrapper';
import AuthMainWrapperFinPrompt from '../../FinPromptProject/AuthMainWrapperFinPrompt';

const cx = classNames.bind(Styles);

const AuthenticationModal = ({
  piwikEnabled,
  auth,
  isFinpromptPages,
}) => {
  const authModalOpened = useSelector(({ authentication }) => (
    authentication.authModalOpened
  ));
  const finPromptAuthModalOpened = useSelector(({ authentication }) => (
    authentication.finPromptAuthModalOpened
  ));
  const dispatch = useDispatch();
  const closeAuthModal = () => {
    dispatch(setAuthModalOpened(false));
    if (isFinpromptPages) dispatch(setFinPromptAuthModalOpened(false));
  };
  const modalOpened = isFinpromptPages ? finPromptAuthModalOpened : authModalOpened;

  return (
    <Modal
      show={modalOpened}
      onHide={closeAuthModal}
      className={cx('auth-modal-holder')}
      dialogClassName={cx('auth-modal', 'sign-in-modal', { 'sign-in-modal--finprompt': isFinpromptPages })}
      centered
      backdropClassName={cx('auth-modal__backdrop', { 'auth-modal__backdrop--finprompt': isFinpromptPages })}
    >
      {isFinpromptPages ? (
        <AuthMainWrapperFinPrompt
          piwikEnabled={piwikEnabled}
          auth={auth}
          hasBigScreenDesign
        />
      ) : (
        <>
          <FontAwesomeIcon className={cx('close-modal')} icon={faXmark} onClick={closeAuthModal} />
          <AuthenticationMainWrapper
            piwikEnabled={piwikEnabled}
            auth={auth}
          />
        </>
      )}
    </Modal>
  );
};

export default withComponentName(AuthenticationModal);
