import classNames from 'classnames/bind';
import Styles from './styles/styles.module.scss';

const cx = classNames.bind(Styles);

const FormSwitcher = ({
  selectedForm,
  sendSelectedFormVal,
  hasBigScreenDesign,
  isFinpromptPages,
}) => (
  <div className={cx('form_switcher', { big_screen: hasBigScreenDesign },
    { finprompt: isFinpromptPages },
    { [`${selectedForm}`]: true })}
  >
    <div className={cx('form_switcher_inner')}>
      <div
        className={cx('form_switcher_btn', { active: selectedForm === 'register' })}
        onClick={() => sendSelectedFormVal('register')}
      >
        Register
      </div>
      <div
        className={cx('form_switcher_btn', { active: selectedForm === 'login' })}
        onClick={() => sendSelectedFormVal('login')}
      >
        Sign in
      </div>
    </div>
  </div>
);

export const FormSwitcherFinPrompt = ({
  selectedForm,
  sendSelectedFormVal,
  hasBigScreenDesign,
}) => (
  <div className={cx('form_switcher-finprompt',
    { 'login-form': selectedForm === 'login' },
    { 'register-form': selectedForm === 'register' },
    { big_screen: hasBigScreenDesign })}
  >
    { selectedForm === 'login' ? (
      <div className={cx('form_switcher-finprompt__inner')}>
        Don’t have an account?
        <span
          className={cx('form_switcher-finprompt__btn')}
          onClick={() => sendSelectedFormVal('register')}
        >
          Register
        </span>
      </div>
    ) : (
      <div className={cx('form_switcher-finprompt__inner')}>
        Do you already have an account?
        <span
          className={cx('form_switcher-finprompt__btn')}
          onClick={() => sendSelectedFormVal('login')}
        >
          Sign In
        </span>
      </div>
    )}
  </div>
);

export default FormSwitcher;
