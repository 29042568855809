import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/pro-light-svg-icons/faExclamation';

import withComponentName from '../../../../decorators/withComponentName';

import { setUserHasAccountModal } from '../../../../actions/authentication.actions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const UserHasAccountModal = ({ setSelectedForm }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setUserHasAccountModal(false));
    setSelectedForm('login');
  };

  return (
    <div className={cx('user-has-account-modal')}>
      <div className={cx('user-has-account-modal__description')}>
        <span
          className={cx('user-has-account-modal__check-icon')}
        >
          <FontAwesomeIcon
            icon={faExclamation}
          />
        </span>
        <p className={cx('user-has-account-modal__text', 'signin')}>
          It appears that you already have a CityFALCON / DCSC
          <br />
          account. Please
          {' '}
          <span
            onClick={handleClick}
            className={cx('user-has-account-modal__link')}
          >
            Sign in
          </span>
          {' '}
          using those credentials.
        </p>
      </div>
      <p className={cx('user-has-account-modal__text')}>
        Signed up for CF using another platform?
        <a
          href="https://www.cityfalcon.ai/help/articles/4405932982417"
          target="_blank"
          className={cx('user-has-account-modal__link')}
          rel="noreferrer"
        >
          {' '}
          Read here
        </a>
        .
      </p>
    </div>
  );
};

export default withComponentName(UserHasAccountModal);
