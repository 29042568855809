import { useState } from 'react';
import classNames from 'classnames/bind';
import loadable from '@utils/loadable';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles/styles.module.scss';

const FormSwitcher = loadable(() => import('./FormSwithcer'));
const AuthenticationInner = loadable(() => import('./AuthenticationInner'));

const cx = classNames.bind(Styles);

const AuthenticationMainWrapper = ({
  piwikEnabled, auth, signInPage, hasBigScreenDesign,
}) => {
  const [selectedForm, setSelectedForm] = useState('register');

  const getLoginTitle = () => {
    if (signInPage) {
      return (<h2>Sign In</h2>);
    }
    return (
      <>
        <h2>SIGN IN</h2>
        <h3>Please log in to access this feature</h3>
      </>
    );
  };

  const getRegisterTitle = () => {
    if (signInPage) {
      return (
        <>
          <h2>
            Register
            {' '}
            <span>
              for FREE
            </span>
          </h2>
          <h3>
            {' '}
            To best leverage our powerful market knowledge platform
          </h3>
        </>
      );
    }
    return (<h2>Register</h2>);
  };

  return (
    <div className={cx('auth-main-wrapper', { 'sign-in-page': signInPage }, { big_screen: hasBigScreenDesign })}>
      <div className={cx('auth-main__title', { [`${selectedForm}`]: true })}>
        {selectedForm === 'login' ? (
          getLoginTitle()
        ) : (
          getRegisterTitle()
        )}
      </div>
      <FormSwitcher
        selectedForm={selectedForm}
        sendSelectedFormVal={setSelectedForm}
        hasBigScreenDesign
      />
      <AuthenticationInner
        piwikEnabled={piwikEnabled}
        selectedForm={selectedForm}
        auth={auth}
        signInPage={signInPage}
        hasBigScreenDesign
      />
    </div>
  );
};

export default withComponentName(AuthenticationMainWrapper);
