/* eslint-disable no-nested-ternary */
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import withComponentName from '../../../decorators/withComponentName';

import { FormSwitcherFinPrompt } from '../../AuthenticationComponents/FormSwithcer';
import { AuthenticationInner } from '../../AuthenticationComponents/AuthenticationInner';
import UserHasAccountModal from './Modals/UserHasAccountModal';

import usePiwik from '../../../hooks/usePiwik';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const AuthFinPromptContent = (props) => {
  const { selectedForm, setSelectedForm } = props;

  const location = useLocation();
  const { piwikEnabled } = usePiwik();

  const userHasAccountModalOpened = useSelector((state) => state.authentication.userHasAccountModalOpened);

  return (
    <div className={cx('auth-content-finprompt')}>
      <div className={cx('auth-content-finprompt__form',
        { [`${selectedForm}`]: true },
        { 'has-account-modal': userHasAccountModalOpened })}
      >
        {!userHasAccountModalOpened && (
          (selectedForm === 'login') ? (
            <h6 className={cx('auth-inner-finprompt__subtitle')}>Sign in using your FinPrompt / CityFALCON details.</h6>
          ) : (
            <h6 className={cx('auth-inner-finprompt__subtitle')}>
              Already have a CityFALCON account?
              {' '}
              <span
                className={cx('auth-inner-finprompt__subtitle--login')}
                onClick={() => setSelectedForm('login')}
              >
                Sign in
              </span>
              {' '}
              instead.
            </h6>
          )
        )}
        {userHasAccountModalOpened ? (
          <UserHasAccountModal
            setSelectedForm={(newState) => setSelectedForm(newState)}
          />
        ) : (
          <>
            <AuthenticationInner
              selectedForm={selectedForm}
              location={location}
              isFinpromptPages
              hasBigScreenDesign
              piwikEnabled={piwikEnabled}
            />
            <FormSwitcherFinPrompt
              selectedForm={selectedForm}
              sendSelectedFormVal={setSelectedForm}
              hasBigScreenDesign
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withComponentName(AuthFinPromptContent);
