import classNames from 'classnames/bind';

import withComponentName from '../../../decorators/withComponentName';

import AuthInnerFinPrompt from './AuthInnerFinPrompt';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const AuthenticationMainWrapperFinPrompt = () => (
  <div className={cx('auth-main-wrapper-finprompt')}>
    <AuthInnerFinPrompt />
  </div>
);
export default withComponentName(AuthenticationMainWrapperFinPrompt);
