import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import withComponentName from '../../../decorators/withComponentName';

import AuthFinPromptBenefits from './AuthFinPromptBenefits';
import AuthFinPromptContent from './AuthFinPromptContent';

import { setFinPromptAuthModalOpened } from '../../../actions/authentication.actions';

import useSizes from '../../../hooks/sizes';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const AuthInnerFinPrompt = () => {
  const { width } = useSizes();
  const dispatch = useDispatch();

  const closeFinPromptAuthModal = () => {
    dispatch(setFinPromptAuthModalOpened(false));
  };

  const [selectedForm, setSelectedForm] = useState('register');

  const userHasAccountModalOpened = useSelector(
    (state) => state.authentication.userHasAccountModalOpened,
  );

  return (
    <div className={cx('auth-inner-finprompt', { [`${selectedForm}`]: true })}>
      <div
        onClick={closeFinPromptAuthModal}
        className={cx('close-modal-finprompt')}
      >
        <span className={cx('close-modal__text')}>Close</span>
        {width > 767 ? (<FontAwesomeIcon className={cx('close-modal__icon')} icon={faXmark} />)
          : (
            <FontAwesomeIcon className={cx('close-modal__icon')} icon={faChevronLeft} />
          )}
      </div>
      <div className={cx('auth-inner-finprompt__form', { [`${selectedForm}`]: true })}>
        <h3 className={cx('auth-inner-finprompt__title')}>
          {selectedForm === 'login' ? (
            'Sign In'
          ) : (
            'Register'
          )}
        </h3>
        {selectedForm === 'register' && !userHasAccountModalOpened ? (
          <h2 className={cx('auth-inner-finprompt__quetsions-subtitle')}>
            to get started with your questions for our chat bot
          </h2>
        ) : null}
        <AuthFinPromptContent
          selectedForm={selectedForm}
          setSelectedForm={(newState) => setSelectedForm(newState)}
        />
      </div>
      <div className={cx('auth-inner-finprompt__benefits')}>
        <AuthFinPromptBenefits />
      </div>
    </div>
  );
};

export default withComponentName(AuthInnerFinPrompt);
